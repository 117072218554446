import { Component, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { ConfigurationService } from './services/configuration/configuration.service';
import { DispatcherService } from './services/dispatcher.service';
import { AdditionalPropertiesConfigurationService } from './services/configuration/additional-properties-configuration.service';
import { CookieService } from 'ngx-cookie';
import { ThemeService } from './services/theme.service';

const COOKIE_SHOW_SIMULATOR = 'show_simulator';

@Component({
  selector: 'my-app',
  templateUrl: './app.component.html',
})
export class AppComponent implements AfterViewChecked {
  isProduction = false;
  showSimulator = false;
  showFooter = false;

  constructor(
    protected configurationService: ConfigurationService,
    private dispatcherService: DispatcherService,
    private additionalPropertiesConfigurationService: AdditionalPropertiesConfigurationService,
    private cookieService: CookieService,
    private changeDetectorRef: ChangeDetectorRef,
    private themeService: ThemeService,
  ) {
    this.isProduction = configurationService.configuration.isProduction;
    this.showSimulator = cookieService.get(COOKIE_SHOW_SIMULATOR) === 'true';
    const DO_NOT_DEL_INIT_THEME_SERVICE = themeService;
  }

  onShowSimulator(): void {
    this.showSimulator = !this.showSimulator;
    this.cookieService.put(COOKIE_SHOW_SIMULATOR, this.showSimulator.toString());
  }

  get mainClassName(): string {
    let classNames = this.isLiteMode ? 'main-lite' : 'main';
    let vuTypeClassName = this.configurationService?.vuType;
    if (vuTypeClassName) {
      classNames += ' ' + vuTypeClassName;
    }
    return classNames;
  }

  get containerClassName(): string {
    return this.isLiteMode ? 'main-lite' : '';
  }

  get simulatorClassName(): string {
    return this.isLiteMode ? 'simulator-container simulator-lite-container' : 'simulator-container';
  }

  get isLiteMode(): boolean {
    return this.additionalPropertiesConfigurationService.isLiteMode;
  }

  get pageFooterVisible(): boolean {
    return this.dispatcherService.isFooterVisible;
  }

  /**
   * detect IE
   * returns version of IE or false, if browser is not Internet Explorer
   */
  detectIE(): any {
    const ua = window.navigator.userAgent;

    // Test values; Uncomment to check result …

    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

    // IE 12 / Spartan
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

    // Edge (IE 12+)
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
  }

  onCallStaffClick(): void {
    this.dispatcherService.vuHttp.callStaff();
  }

  ngAfterViewChecked(): void {
    const showFooter = this.isShowFooter;
    if (showFooter !== this.showFooter) {
      this.showFooter = showFooter;
      this.changeDetectorRef.detectChanges();
    }
  }

  get isShowFooter(): boolean {
    return !this.isLiteMode && this.pageFooterVisible;
  }

  get isShowHeader(): boolean {
    return !this.isLiteMode;
  }
}
