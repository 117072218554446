import { Injectable } from '@angular/core';
import { SaleService } from '../../sale.service';
import { LoggingService } from '../../logging/logging.service';
import { Observable, of, Subscription } from 'rxjs';
import { BarcodeReaderService } from '../../barcode/barcode-reader.service';
import { RfidCardReaderService } from '../../rfid-card/rfid-card-reader.service';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { CashlessBaseProviderService } from './cashless-base-provider.service';
import { VuCommunicationService } from '../../vu/vu-communication.service';
import { CashlessPaymentProviderEventType } from 'src/app/lib/payment/cashless-payment-provider-event-type';
import { ConfigurationService } from '../../configuration/configuration.service';
import { RfidCardPaymentDeviceType } from 'src/app/lib/payment/rfid-card-payment-device-type';
import { NotificationService } from '../../notification/notification.service';
import { NotificationMessageTypeEnum } from 'src/app/lib/notification-message/notification-message-type.enum';
import { CardInformation } from 'src/app/modules/recharge-card/models/card-information';
// import { BasketUpdateItem } from 'src/app/modules/recharge-card/models/basket-update-item';
// import { OrderLine } from 'src/app/lib/lib';


@Injectable()
export class CashlessGiftCardProviderService extends CashlessBaseProviderService {

  private _autoScanSubscription: Subscription;

  private _barcodeScannedSubscription: Subscription;
  private _rfidScannedSubscription: Subscription;

  constructor(
    protected log: LoggingService,
    protected saleService: SaleService,
    protected vuCommunicationService: VuCommunicationService,
    private barcodeReaderService: BarcodeReaderService,
    private rfidCardReaderService: RfidCardReaderService,
    private configurationService: ConfigurationService,
    private notificationService: NotificationService,
  ) {
    super(log, saleService, vuCommunicationService);
  }

  get name(): string {
    return CashlessGiftCardProviderService.name;
  }

  protected _subscribeEvents(): void {
    this.raiseTextEvent(this._pleaseScanMessage);

    if (this.barcodeScanAvailable) {
      this._barcodeScannedSubscription = this.barcodeReaderService.barcodeScanned
        .pipe(
          switchMap(barcode => this._onScanCode(barcode, true))
        )
        .subscribe();
      this._barcodeLedEnable(true);
    }

    if (this.rfidScanAvailable) {
      this._rfidScannedSubscription = this.rfidCardReaderService.rfidCardScanned
        .pipe(
          switchMap(barcode => this._onScanCode(barcode, false))
        )
        .subscribe();
      this._rfidCardReaderEnable(true);
    }

    if (this.paymentData?.code) {
      this._autoScanSubscription = this._onScanCode(this.paymentData?.code, true).subscribe();
    }
  }

  protected _unsubscribeEvents(): void {
    if (this._autoScanSubscription) {
      this._autoScanSubscription.unsubscribe();
      this._autoScanSubscription = null;
    }

    if (this._barcodeScannedSubscription) {
      this._barcodeScannedSubscription.unsubscribe();
      this._barcodeScannedSubscription = null;
      this._barcodeLedEnable(false);
    }

    if (this._rfidScannedSubscription) {
      this._rfidScannedSubscription.unsubscribe();
      this._rfidScannedSubscription = null;
      this._rfidCardReaderEnable(false);
    }

    super._unsubscribeEvents();
  }

  private get barcodeScanAvailable(): boolean {
    return this.configurationService.configuration.rfidCardPaymentDeviceType === RfidCardPaymentDeviceType.All
      || this.configurationService.configuration.rfidCardPaymentDeviceType === RfidCardPaymentDeviceType.Barcode;
  }

  private get rfidScanAvailable(): boolean {
    return this.configurationService.configuration.rfidCardPaymentDeviceType === RfidCardPaymentDeviceType.All
      || this.configurationService.configuration.rfidCardPaymentDeviceType === RfidCardPaymentDeviceType.RFID;
  }

  private _barcodeLedEnable(value: boolean): void {
    this.barcodeReaderService.barcodeLedEnable(value);
  }

  private _rfidCardReaderEnable(value: boolean): void {
    this.rfidCardReaderService.rfidCardReaderEnable(value);
  }

  private _onScanCode(scanCode: string, isCard: boolean): Observable<any> {
    return of(scanCode).pipe(
      tap(code => { this.raiseTextEvent(this._validationInProgressMessage); }),
      switchMap(code => {
        return this.vuCommunicationService.vuHttp.getCardPaymentInformation(code);
      }),
      switchMap(cardInformation => {
        if (!cardInformation || !this.saleService.order) {
          return;
        }

        // this._updateOrderLines(cardInformation.basketUpdate);

        if (cardInformation.allowPaymentAmount(this.saleService.order.amountTotal.value)) {
          return this.vuCommunicationService.vuHttp.cardPaymentScan(cardInformation.name, this.saleService.order.amountTotal.value).pipe(
            tap(result => {
              if (result) {
                this.saleService.order.giftCardEan13 = cardInformation.ean13;
                this.saleService.order.giftCardName = cardInformation.name;
                this.saleService.remoteTransaction.closeRemoteTransaction(true, 'GiftCard. PaymentCompleted').then(() => {
                  this.raiseCashlessEvent(CashlessPaymentProviderEventType.PaymentCompleted, '');
                }); // to cashless machine ?
              }
            }),
          );
        }

        if (cardInformation.allowPartialPayment() && this.configurationService.configuration.giftCardPartialPayment) {
          this.saleService.order.giftCardEan13 = cardInformation.ean13;
          this.saleService.order.giftCardName = cardInformation.name;
          this.saleService.order.giftCardPartialPaymentAmount = cardInformation.balance;
          this.raiseCashlessEvent(CashlessPaymentProviderEventType.PaymentAborted, `Gift partial payment acquired, card balance ${cardInformation.balance}`);
          return of(false);
        }

        this.raiseTextEvent(this._pleaseScanAnotherMessage(isCard));
        this._showCartInformationErrorMessage(cardInformation);
        return of(false);
      }),
      catchError(error => {
        this.log.error(`Validate code error: ${error}`);
        this.raiseTextEvent(this._pleaseScanAnotherMessage(isCard));
        return of(false);
      }),
    );
  }

  // private _updateOrderLines(backetUpdateItems: Array<BasketUpdateItem>): void {
  //   if (!backetUpdateItems || !backetUpdateItems.length || !this.saleService.order) {
  //     return;
  //   }

  //   const order = this.saleService.order;

  //   backetUpdateItems.forEach((basketUpdateItem: BasketUpdateItem) => {
  //       if (!basketUpdateItem.productId || basketUpdateItem.quantity == null){
  //         return;
  //       }

  //       const productLines = order.orderLines.filter((x: OrderLine) => x.product && basketUpdateItem.productId === x.productId);

  //       if (productLines && productLines.length) {
  //         productLines.forEach((x: OrderLine) => {
  //           x.quantity = basketUpdateItem.quantity;
  //           x.price = basketUpdateItem.price;
  //         });
  //       }
  //   });
  // }

  private get _pleaseScanMessage(): string {
    if (this.barcodeScanAvailable && this.rfidScanAvailable) {
      return 'Please scan card or barcode';
    }

    if (this.barcodeScanAvailable) {
      return 'Please scan barcode';
    }

    if (this.rfidScanAvailable) {
      return 'Please scan card';
    }

    return '';
  }

  private get _validationInProgressMessage(): string {
    return 'Validation is in progress';
  }

  private _pleaseScanAnotherMessage(isCard: boolean): string {
    if (isCard) {
      return 'Card is not valid, please scan another card';
    }

    return 'Barcode is not valid, please scan another barcode';
  }

  private _showCartInformationErrorMessage(cardInformation: CardInformation): void {
    if (!cardInformation?.errorMessage) {
      return;
    }

    this.notificationService.show(cardInformation.errorMessage, NotificationMessageTypeEnum.Error);
  }
}
