import { TranslatePropertyHelper } from "./languages/translation-properties-helper";
import { ILog } from "../services/logging/log.interface";
import { GlobalSettings } from "./lib";

export class DictionaryHelper {

  private log: ILog = GlobalSettings.getLog();

  private internalProperties = {};
  private translatePropertyHelper: TranslatePropertyHelper;

  constructor(
    properties: any,
  ) {
    this.properties = properties;
  }

  get properties(): any {
    return this.internalProperties;
  }

  set properties(value: any) {
    this.internalProperties = value;
    this.translatePropertyHelper = new TranslatePropertyHelper(value ? value['translations'] : null);
  }

  getProperty(propertyName: string): any {
    if (!this.properties) {
      return null;
    }

    return this.properties[propertyName];
  }

  isPropertyExist(propertyName: string): boolean {
    return !!this.getProperty(propertyName);
  }

  getString(propertyName: string, defaultValue = ''): string {
    const value = this.getProperty(propertyName);
    if (!value) {
      return defaultValue;
    }

    return value;
  }

  getTranslatedString(language: string, propertyName: string): string {
    return this.translatePropertyHelper.getTranslationByFieldName(language, propertyName, this.getString(propertyName));
  }

  getJSONFromString(propertyName: string, defaultValue = null): string {
    let value = defaultValue;
    const jsonString = this.getString(propertyName);
    try {
      if (jsonString) {
        value = JSON.parse(this.getString(propertyName));
      }
    } catch (e) {
      this.log.warning(`Can't parse as JSON. Property: ${propertyName}, Value: ${jsonString}`);
    }
    return value;
  }

  getNumber(propertyName: string, defaultValue = 0): number {
    const value = this.getProperty(propertyName);
    if (!value) {
      return defaultValue;
    }

    return Number(value);
  }

  getBoolean(propertyName: string, defaultValue = false): boolean {
    const value = this.getProperty(propertyName);
    if (!value) {
      return defaultValue;
    }

    return value.toString().toLowerCase() === 'true';
  }

  getDate(propertyName: string, defaultValue: Date = null): Date {
    const value = this.getProperty(propertyName);
    if (!value) {
      return defaultValue;
    }

    return new Date(value);
  }

  getNumberArray(propertyName: string, defaultValue: number[] = null): number[] {
    if (!this.properties) {
      return defaultValue;
    }

    const value: number[] = this.properties[propertyName];
    if (!value) {
      return defaultValue;
    }

    return value;
  }

  getStringArray(propertyName: string, defaultValue: string[] = null): string[] {
    if (!this.properties) {
      return defaultValue;
    }

    const value: string[] = this.properties[propertyName];
    if (!value) {
      return defaultValue;
    }

    return value;
  }

  setProperty(propertyName: string, value: any): void {
    if (!this.properties) {
      return null;
    }

    return this.properties[propertyName] = value ? value.toString() : null;
  }

  toString(): string {
    if (!this.properties) {
      return '';
    }

    let result = '';
    for (const key in this.properties) {
      if (!this.properties.hasOwnProperty(key)) {
        continue;
      }
      const value = this.properties[key];
      if (result) {
        result += ';';
      }
      result += `"${key}"="${value}"`;
    }
    return result;
  }

  getPropertyHelper(propertyName: string): DictionaryHelper {
    const properties = this.getProperty(propertyName);
    if (!properties) {
      return null;
    }

    return new DictionaryHelper(properties);
  }
}
