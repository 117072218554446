import { Injectable } from '@angular/core';
import { CreditCardTerminalEvent } from 'src/app/lib/credit-card/credit-card-terminal-event';
import { CreditCardTerminalEventType } from 'src/app/lib/credit-card/credit-card-terminal-event-type';
import { CashlessPaymentProviderEventType } from 'src/app/lib/payment/cashless-payment-provider-event-type';
import { CashlessBaseProviderService } from './cashless-base-provider.service';
import { Subscription } from 'rxjs';
import { LoggingService } from '../../logging/logging.service';
import { SaleService } from '../../sale.service';
import { VuCommunicationService } from '../../vu/vu-communication.service';
import { ConfigurationService } from '../../configuration/configuration.service';
import { PrintService } from '../../print/print.service';

@Injectable()
export class CashlessCardProviderService extends CashlessBaseProviderService {
  private _cardTerminalEvent: Subscription;

  constructor(
    protected log: LoggingService,
    protected saleService: SaleService,
    protected vuCommunicationService: VuCommunicationService,
    private configurationService: ConfigurationService,
    private printService: PrintService,
  ) {
    super(log, saleService, vuCommunicationService)
  }

  get name(): string {
    return CashlessCardProviderService.name;
  }

  protected _subscribeEvents(): void {
    this._cardTerminalEvent = this.vuCommunicationService.vuConnection.eventCreditCardTerminalEvent.subscribe(
      (x: CreditCardTerminalEvent) => this.onEventCreditCardTerminalEvent(x)
    );

    super._subscribeEvents();
  }

  protected _unsubscribeEvents(): void {
    if (this._cardTerminalEvent) {
      this._cardTerminalEvent.unsubscribe();
      this._cardTerminalEvent = null;
    }
    super._unsubscribeEvents();
  }

  private onEventCreditCardTerminalEvent(x: CreditCardTerminalEvent): void {
    this.log.info(`onEventCreditCardTerminalEvent. ${x}`);

    switch (x.eventType) {
      case CreditCardTerminalEventType.CardInserted:
        this.log.info('onCardInserted');
        this.raiseCashlessEvent(CashlessPaymentProviderEventType.TextChanged, 'Follow the instructions on the card reader');
        break;
      case CreditCardTerminalEventType.CardRemoved:
        this.log.info('onCardRemoved');
        break;
      case CreditCardTerminalEventType.TerminalTextChanged:
        this.raiseCashlessEvent(CashlessPaymentProviderEventType.TextChanged, x.eventInfo);
        break;
      case CreditCardTerminalEventType.PaymentCompleted:
        this.log.info('onPaymentCompleted');
        this.raiseCashlessEvent(CashlessPaymentProviderEventType.PaymentCompleted, '');
        break;
      case CreditCardTerminalEventType.PaymentAborted:
        this.log.info('onPaymentAborted');
        const creditCardReceipt = this.saleService.paymentSession.cardTerminalReceipt;
        if (creditCardReceipt && this.configurationService.configuration.printAbortCardTerminalReceipt) {
          this.printService.printCreditCardReceipt(this.saleService.order.id, creditCardReceipt);
        }
        this.raiseCashlessEvent(CashlessPaymentProviderEventType.PaymentAborted, '');
        break;
      case CreditCardTerminalEventType.FundsReservationIsCompleted:
        this.log.info('FundsReservationIsCompleted');
        this.saleService.remoteTransaction.closeRemoteTransaction(true, 'CreditCardTerminal. onToPaymentCompleted');
        break;
      case CreditCardTerminalEventType.ReceiptReceived:
        this.log.info('ReceiptReceived');
        this.saleService.paymentSession.cardTerminalReceipt = x.eventInfo;
        this.saleService.remoteTransaction.appendTransactionInfo(x.eventInfo); // to cashless machine ???
        break;
      default:
        this.log.error(`This CreditCardTerminalEvent not supported: ${x}`);
        break;
    }
  }

}
