import { Component, Input, ViewEncapsulation, LOCALE_ID } from '@angular/core';
import { ConfigurationService } from '../../../services/configuration/configuration.service';
import { LanguageService, getLanguageFactory } from '../../../services/language.service';
import { Ticket, TicketStatus } from '../../../lib/lib';
import { StatusInfo } from '../../../lib/status-info';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ticket-status',
  templateUrl: './ticket-status.component.html',
  styleUrls: ['./ticket-status.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class TicketStatusComponent {

  @Input()
  ticket: Ticket;

  @Input()
  ticketInfoWithTimePeriodMode: string;

  constructor(
    private configurationService: ConfigurationService,
    private languageService: LanguageService,
    private translateService: TranslateService,
  ) {
  }

  get status(): TicketStatus {
    if (!this.ticket) {
      return TicketStatus.Unknown;
    }

    if (this.ticket.isDisabled) {
      return TicketStatus.Disabled;
    }

    if (this.ticketInfoWithTimePeriodMode) {
      var validate_date = null;
      if (this.ticketInfoWithTimePeriodMode == 'before_and_during' && this.ticket.additionalProperties.valid_to) {
          validate_date = new Date(this.ticket.additionalProperties.valid_to);
      }
      if (this.ticketInfoWithTimePeriodMode == 'before' && this.ticket.additionalProperties.valid_from) {
          validate_date = new Date(this.ticket.additionalProperties.valid_from);
      }

      if (validate_date) {
          var now = new Date();
          if (now > validate_date) {
              return TicketStatus.Invalid;
          }

          return TicketStatus.Valid;
      }
  }

    return this.ticket.status || TicketStatus.Unknown;
  }

  get statusInfo(): StatusInfo {
    return StatusInfo.getStyleByTicketStatus(this.status);
  }

  get isRoleExit(): boolean {
    return this.configurationService.isRoleExit;
  }

  get isStaff() {
    return this.statusInfo.status === TicketStatus.Staff;
  }

  get language(): string {
    return this.languageService.odooLanguageName;
  }

  get productName(): string {
    return this.ticket == null ? '' : this.ticket.productFormattedName(this.language);
  }

  get viewQuantityInfo(): boolean {
    return this.ticket && !this.ticket.isUnknown && !this.isStaff && this.ticket.quantity > 1;
  }

  get isTicketValid() {
    return this.ticket && this.status === TicketStatus.Valid;
  }

  get statusInfotext(): string {
    return this.translateService.instant(this.statusInfo.text);
  }
}
