import { DictionaryHelper } from '../../dictionary-helper';
import { Product, GlobalSettings } from '../../lib';
import { BaseVisualItem } from '../../visual-item/base-visual-item';
import { DisplayItemTypeEnum } from './display-item-type.enum';
import { TranslatePropertyHelper } from '../../languages/translation-properties-helper';
import { CIOBoardPinEvent } from '../../cioboard/cioboard-pin-event';
import { Money } from '../../money/money';

export class DisplayItem extends BaseVisualItem {

  private _posX: number = null;
  private _posY: number = null;
  private _product: Product = null;
  private _dictionaryHelper: DictionaryHelper;
  private translatePropertyHelper: TranslatePropertyHelper;
  private _isDisabled: boolean = false;

  values: any;
  navigationItem: DisplayItem;
  failureItem: DisplayItem;
  children: DisplayItem[];

  static fromArrayJson(data: any): DisplayItem[] {
    const result = new Array<DisplayItem>();
    if (data && data instanceof Array) {
      data.forEach(item => {
        result.push(DisplayItem.fromJson(item));
      });
    }
    return result;
  }

  static fromJson(data: any): DisplayItem {
    if (data == null) {
      return null;
    }
    return new DisplayItem(data);
  }

  constructor(
    values: any = {},
  ) {
    super();
    this._dictionaryHelper = new DictionaryHelper(values);
    this.values = values;
    this.translatePropertyHelper = new TranslatePropertyHelper(values ? values.translations : null);
  }

  public toString(): string {
    let m = `Display Item. id: '${this.id}'. `;
    m += `values: ${this._dictionaryHelper.properties}. `;
    return m;
  }

  get id(): number {
    return this._dictionaryHelper.getNumber('id');
  }

  get name(): string {
    return this._dictionaryHelper.getString('name');
  }

  get sequence(): number {
    return this._dictionaryHelper.getNumber('sequence');
  }

  get description(): string {
    return this._dictionaryHelper.getString('description');
  }

  get isDynamic(): boolean {
    return this._dictionaryHelper.getBoolean('dynamic');
  }

  translateDescription(language: string): string {
    return this.translatePropertyHelper.getTranslationByFieldName(language, 'description', this.description);
  }

  translateHtmlTemplate(language: string): string {
    return this.translatePropertyHelper.getTranslationByFieldName(language, 'html_template', this.htmlTemplate);
  }

  translateInputPlacegolder(language: string): string {
    return this.translatePropertyHelper.getTranslationByFieldName(language, 'text_input_placeholder',
      this.textInputPlaceholder);
  }

  get product(): Product {
    return this._product;
  }

  set product(product: Product) {
    this._product = product;
  }

  get productId(): number {
    return this._dictionaryHelper.getNumber('product_reference');
  }

  get categoryId(): number {
    return this._dictionaryHelper.getNumber('category_reference');
  }

  get posX(): number {
    if (this._posX == null) {
      return this._dictionaryHelper.getNumber('pos_x');
    }

    return this._posX;
  }

  set posX(value: number) {
    this._posX = value;
  }

  get posY(): number {
    if (this._posY == null) {
      return this._dictionaryHelper.getNumber('pos_y');
    }
    return this._posY;
  }

  set posY(value: number) {
    this._posY = value;
  }

  get sizeHorizontal(): number {
    return this._dictionaryHelper.getNumber('size_horizontal');
  }

  get sizeVertical(): number {
    return this._dictionaryHelper.getNumber('size_vertical');
  }

  get htmlTemplate(): string {
    return this._dictionaryHelper.getString('html_template');
  }

  get type(): string {
    return this._dictionaryHelper.getString('type');
  }

  get zIndex(): number {
    return this._dictionaryHelper.getNumber('z_index');
  }

  get assetId(): number {
    return this._dictionaryHelper.getNumber('asset');
  }

  get hideBackButton(): boolean {
    return this._dictionaryHelper.getBoolean('hide_back_button');
  }

  get printTemplateTypeUniqueName(): string {
    return this._dictionaryHelper.getString('print_template_type_unique_name');
  }

  get printTemplateTypeId(): string {
    return this._dictionaryHelper.getString('print_template_type');
  }

  get apiUrl(): string {
    return this._dictionaryHelper.getString('api_url');
  }

  get apiReadRequestUrl(): string {
    return this._dictionaryHelper.getString('api_read_request_url');
  }

  get navigationId(): number {
    return this._dictionaryHelper.getNumber('navigation_item_id');
  }

  get failureId(): number {
    return this._dictionaryHelper.getNumber('failure_item_id');
  }

  get openGateType(): string {
    return this._dictionaryHelper.getString('open_gate_type');
  }

  get previousDisplayItem(): boolean {
    return this._dictionaryHelper.getBoolean('previous_display_item');
  }

  get startPosX(): number {
    return this.posX;
  }

  get startPosY(): number {
    return this.posY;
  }

  get endPosX(): number {
    return this.posX + this.sizeHorizontal;
  }

  get endPosY(): number {
    return this.posY + this.sizeVertical;
  }

  get isVisibleInGrid(): boolean {
    return this.children && this.children.length > 0 || this.isProduct || Boolean(this.type);
  }

  get pinLength(): number {
    return this._dictionaryHelper.getNumber('pin_length');
  }

  get minAmount(): number {
    return this._dictionaryHelper.getNumber('min_amount');
  }

  get maxAmount(): number {
    return this._dictionaryHelper.getNumber('max_amount');
  }

  get textLength(): number {
    return this._dictionaryHelper.getNumber('text_length');
  }

  get textInputPlaceholder(): string {
    return this._dictionaryHelper.getString('text_input_placeholder');
  }

  get minTextInputAction(): number {
    return this._dictionaryHelper.getNumber('min_text_input_action');
  }

  get customPrice(): number {
    return this._dictionaryHelper.getNumber('custom_price');
  }

  positionInGrid(gridColumnCount: number, gridRowCount: number): boolean {
    return this.posX && this.posY && gridColumnCount + 1 >= this.endPosX && gridRowCount + 1 >= this.endPosY;
  }

  get productPrice(): Money {
    if (!this.product) {
      return null;
    }
    if (this.customPrice) {
      return new Money(this.customPrice, Money.getCurrencyCode(this.product.price));
    }
    return this.product.price;
  }

  getformattedHtmlTemplate(language: string, placeholdersData: Map<string, any> = new Map<string, any>()): string {
    let template = this._fixLineBreak(this.translateHtmlTemplate(language));
    template = this._fillPlaceholders(template, placeholdersData);
    if (Product.isProduct(this.product)) {
      return this._fillProductPlaceholders(template, this.product, language, this.productPrice);
    }

    const name = this.translateName(language);
    return name ? template.replace('@product_name', name).replace('@price', this.price) : template;
  }

  get navigateToRootPage(): boolean {
    return (this.type === DisplayItemTypeEnum.Category || this.type === DisplayItemTypeEnum.FmcuAction)
      && (!this.children || this.children.length === 0);
  }

  get canUseNavigationItem(): boolean {
    return (this.type === DisplayItemTypeEnum.Category || this.type === DisplayItemTypeEnum.FmcuAction) && this.timeoutToShowNextItem <= 0;
  }

  get canUseExternalNavigation(): boolean {
    return this.type === DisplayItemTypeEnum.Category && !!this.apiUrl;
  }

  set isDisabled(value: boolean) {
    this._isDisabled = value;
  }

  get isDisabled(): boolean {
    return this._isDisabled;
  }

  get uniqueName(): string {
    return this._dictionaryHelper.getString('unique_name');
  }

  get timeoutToShowNextItem(): number {
    return this._dictionaryHelper.getNumber('timeout_to_show_next_item');
  }

  get timeout(): number {
    return this._dictionaryHelper.getNumber('timeout');
  }

  get customLayout(): any {
     return this._dictionaryHelper.getJSONFromString('custom_layout');
  }

  get cioboardPins(): CIOBoardPinEvent[] {
    const cioboardStates = this._dictionaryHelper.getProperty('cioboard_pin_events');
    return CIOBoardPinEvent.fromArray(cioboardStates);
  }

  getFormattedName(language: string): string {
    if (!this.translatePropertyHelper) {
      return this.translateName(language);
    }
    return this.translatePropertyHelper.getTranslationByFieldName(language, 'name', this.name);
  }
}
