import { VisualItem, AcceptedCash, Order, Product, Money, VuState, PrintTask, DtoVuConfiguration, TicketUse, CreditCard, Ticket, PaymentMethod } from '../../../lib/lib';
import { ScreenSaverConfiguration } from '../../../lib/screen-saver-configuration';
import { Observable } from 'rxjs';
import { TouchTileColor } from '../../../lib/touch-tile/touch-tile-color';
import { TouchTileSwitch } from '../../../lib/touch-tile/touch-tile-switch';
import { RunningLightScenario } from '../../../lib/touch-tile/running-light-scenario';
import { CreditCardTerminalState } from '../../../lib/credit-card/credit-card-terminal-state';
import { DisplayConfiguration } from '../../../lib/display/configuration/display-configuration';
import { CIOBoardPinEvent } from '../../../lib/cioboard/cioboard-pin-event';
import { MoneyExchangeRules } from '../../../lib/money-exchange/money-exchange-rules';
import { Issue } from '../../../lib/issue/issue';
import { FmcuState } from 'src/app/modules/fmcu-access/models/fmcu-state';
import { CardInformation } from 'src/app/modules/recharge-card/models/card-information';
import { CardDispenserStatus } from 'src/app/lib/rfid-card/card-dispenser-status';
import { RfidCardData } from 'src/app/lib/card-dispenser/rfid-card-data';
export const API_PRINT = `api/print`;
export const API_SYSTEM_PRINT = `api/print/system-print`;
export const API_PRINT_TEMPLATE = `api/print/template-by-unique-name`;
export const API_SAVE_ORDER = `api/save-order`;
export const API_CANCEL_ORDER = `api/cancel-order`;
export const API_CIOBOARD_OUTPUT_PIN = `api/cioboard`;
export const API_UPDATE_ANGULAR_STATE = `api/update/angular/state`;


export interface IVuHttp {
  isUseProductionApi: boolean;

  getProducts(): Promise<Product[]>;
  getProductsByIds(productIds: number[]): Promise<Product[]>;
  getProduct(productId: number): Promise<Product>;
  getSubProduct(productId: number): Promise<Product>;
  calculateProductsPrice(productsInfo: any, priceListId: number): Promise<number[]>;
  getVisualItems(type: string): Promise<VisualItem[]>;

  getDisplayConfiguration(configurationId: number): Promise<DisplayConfiguration>;

  getVuState(): Promise<VuState>;
  getVuConfiguration(): Promise<DtoVuConfiguration>;
  getTicketUse(code: string): Promise<TicketUse[]>;

  saveOrder(order: Order): Promise<any>;
  cancelOrder(order: Order): Promise<any>;
  resetOrderRfidCardAccessData(orderId: number, cardNumber: string): Promise<any>;

  print(task: PrintTask): Promise<any>;
  printByTemplateTypeUniqueName(templateTypeUniqueName: string, language: string): Promise<any>;
  openFmcuApi(url: string, body: object): Promise<any>;
  printApi(): Promise<any>;

  getPrintXmlTemplate(task: PrintTask): Promise<string>;

  getAcceptedCash(amount: Money): Promise<AcceptedCash>;
  getAcceptedCreditCards(): Promise<CreditCard[]>;

  beginPaymentTransaction(amount: Money, paymentMethod: PaymentMethod, giftName: string, giftAmount: Money): Promise<void>;
  commitPaymentTransaction(force: boolean): Promise<void>;
  appendTransactionInfo(info: string): Promise<void>;
  revertPaymentTransaction(allowRefundAngular: boolean): Promise<any>;
  resetRemoteTransaction(): Observable<boolean>;

  revertCashlessTransaction(paymentMethod: PaymentMethod): Promise<any>;
  abortCashlessTransaction(paymentMethod: PaymentMethod): Promise<any>;

  returnAmount(amount: Money): Promise<any>;

  barcodeLedEnable(value: boolean): void;
  rfidCardLedEnable(value: boolean): void;
  rfidCardReaderEnable(value: boolean): void;

  scanTicket(barcode: string): void;

  getTicketInfo(barcode: string): Observable<Ticket>;

  activateOneDayTicket(ticketCode: string): Observable<Ticket>;

  printTicket(ticketCode: string): Promise<any>;

  callStaff(): void;

  getScreenSaverConfiguration(): Promise<ScreenSaverConfiguration>;

  openEnter(): Observable<boolean>;
  closeEnter(): Observable<boolean>;

  demoPayment(): Observable<any>;

  changeTouchTileColor(touchTileColor: TouchTileColor[]): Observable<boolean>;
  changeTouchTileSwitch(touchTileSwitch: TouchTileSwitch[]): Observable<boolean>;
  setRunningLight(runningLightScenario: RunningLightScenario): Observable<boolean>;

  changeTouchTileManualMode(value: boolean): Observable<boolean>;

  switchLntBoard(enabled: boolean): void;

  getCreditCardTerminalState(): Observable<CreditCardTerminalState>;

  creditCardTerminalAbort(): Observable<any>

  gateReadBarcode(barcode: string, baseUrl: string, openGateType: string, timeout: number): Observable<any>;

  changeServerLanguage(localeId: string): void;

  writeLogMessages(messages: string[]): Promise<any>;

  canPayoutAmount(amount: Money): Promise<boolean>;

  howMuchCanPayoutAmount(amount: Money): Promise<Money>;

  cioBoardAction(pins: CIOBoardPinEvent[]): Promise<any>;

  updateAngularPingState(): Promise<boolean>;

  produceRfidCard(): Promise<any>;
  releaseRfidCard(): Promise<any>;
  startTakingRfidCard(): Promise<any>;
  stopTakingRfidCard(): Promise<any>;
  captureRfidCard(): Promise<any>;
  cardDispenserStatus(): Observable<CardDispenserStatus>;
  writeRfidCardData(rfidCardData: RfidCardData): Promise<any>;
  readRfidCardData(rfidCardData: RfidCardData): Promise<any>;

  startTransaction(): Promise<any>;
  stopTransaction(): Promise<any>;

  isCardTerminalAvailable(): Observable<boolean>;

  getMoneyExchangeRules(): Observable<MoneyExchangeRules>;

  isMoneyExchangeTransactionExist(): Observable<boolean>;

  beginMoneyExchangeTransaction(acceptedCash: AcceptedCash): Observable<boolean>;

  rollbackMoneyExchangeTransaction(): Observable<boolean>;

  commitMoneyExchangeTransaction(): Observable<boolean>;

  moneyExchangePayoutMoney(payoutAcceptedCash: AcceptedCash): Observable<boolean>;

  createIssue(issue: Issue): Observable<number>;

  beginUnrestrictedCashPaymentTransaction(): Observable<boolean>;
  commitUnrestrictedCashPaymentTransaction(): Observable<boolean>;
  resetUnrestrictedCashPaymentTransaction(): Observable<boolean>;
  getAcceptedCashWithoutRestrictions(): Observable<AcceptedCash>;

  getOrderCache(): Observable<any>;

  getFmcuState(baseUrl: string): Observable<FmcuState>;

  gateClose(baseUrl: string): Observable<any>;

  getCardPaymentInformation(code: string): Observable<CardInformation>;
  cardPaymentScan(code: string, amount: number): Observable<boolean>;
}

export interface IVuHttpSimulator {
  setScreenSaverConfiguration(screenSaverConfiguration: ScreenSaverConfiguration): void;
}
